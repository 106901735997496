import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
const BuyNowQuantityChange = ({
  handleQuantity,
  orderQty,
  availableForSale,
}) => {
  const isQtyAvailable = Number(availableForSale) ? true : false;
  return (
    <div
      data-tooltip-id='my-tooltip'
      data-tooltip-content='Quantity not available'
      className='flex rounded-lg bg-[#F3F3F3] text-sm md:text-base py-1 px-2 border'
    >
      <button
        disabled={!isQtyAvailable}
        onClick={() => handleQuantity('remove')}
        className='w-8 text-xl font-bold text-[#A3A3A3]'
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
        </svg>

      </button>
      <button
        disabled={!isQtyAvailable}
        className='w-8 text-xl font-bold text-[#3A4980] md:w-10'
      >
        {orderQty}
      </button>
      <button
        disabled={!isQtyAvailable}
        onClick={() => handleQuantity('add')}
        className='w-8 text-xl font-bold text-[#3A4980]'
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>

      </button>
      {!isQtyAvailable && <Tooltip id='my-tooltip' />}
    </div>
  );
};

export default BuyNowQuantityChange;
